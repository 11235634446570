
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Footer',
  data() {
    return {
      isLoadFooter: false,
      stateLoadFooter: false,
      isLiveChat: false,
      // isScrollToTop: false,
      isCookiePDP: false,
      cookieMargin: '',
      formsUrl: 'https://officemate.formstack.com/forms/ofm_2024_csat',
      currentUrl: '',
    }
  },
  watch: {
    $route(to, from) {
      this.currentUrl = window.location.href
    },
  },
  computed: {
    ...mapGetters('user', ['consentCookieStatus', 'profileType']),
    displayCookiePolicy() {
      return this.$utils.isValid(this.consentCookieStatus) &&
        this.consentCookieStatus === 'accept'
        ? false
        : true
    },
    formsHref() {
      if (this.currentUrl.includes('/en')) {
        return this.formsUrl + '_en'
      } else {
        return this.formsUrl
      }
    },
  },
  beforeMount() {
    window.addEventListener('scroll', this.onBoundingLoad)
  },
  beforeDestroy() {
    this.promotionBanner = []
    window.removeEventListener('scroll', this.onBoundingLoad)
    this.$nuxt.$off('cookieShift', () => {
      this.isCookiePDP = false
      this.cookieMargin = 0
    })
  },
  created() {
    // {
    //   hid: 'insider-object',
    //   type: 'text/javascript',
    //   charset: 'utf-8',
    // },
    this.$nuxt.$on('cookieShift', (e) => {
      // console.log('test', e)
      this.isCookiePDP = e.isShift
      this.cookieMargin = e.height
      console.log('height', e.height)
    })
  },
  mounted() {
    console.log('this.currentUrl', this.currentUrl)
    // setTimeout(() => {
    this.livechat()
    // }, 5000)
    if (this.displayCookiePolicy) {
      document.body.classList.add('cookie-consent')
    }
    // // create insider
    // if (!document.getElementById('insider-object')) {
    //   const script = document.createElement('script')
    //   script.setAttribute('id', 'insider-object')
    //   script.type = 'text/javascript'
    //   // script.async = true
    //   document.body.appendChild(script)
    // } else {
    //   document.getElementById('insider-object').innerHTML = ''
    // }

    // const insiderApi =
    //   this.$nuxt.context.store.getters['user/profileType'] !== null &&
    //   this.$nuxt.context.store.getters['user/profileType'] === 'eprocurement'
    //     ? '//eprocurementth.api.useinsider.com/ins.js?id=10002769'
    //     : '//eprocurementth.api.useinsider.com/ins.js?id=10006310'
    // if (document.getElementById('insider-script')) {
    //   document.getElementById('insider-script').src = insiderApi
    // } else {
    //   const script = document.createElement('script')
    //   script.setAttribute('id', 'insider-script')
    //   script.type = 'text/javascript'
    //   script.src = insiderApi
    //   // script.async = true
    //   document.body.appendChild(script)
    // }
  },
  methods: {
    ...mapActions('user', ['setConsentCookieStatus']),
    scrollToTop() {
      function preventScroll(e) {
        e.preventDefault()
        e.stopPropagation()

        return false
      }
      window.addEventListener('wheel', preventScroll, {
        passive: false,
      })

      const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setTimeout(() => {
          window.removeEventListener('wheel', preventScroll, {
            passive: false,
          })
        }, 500)
        // const c = document.documentElement.scrollTop || document.body.scrollTop
        // if (c > 60) {
        //   window.requestAnimationFrame(scrollToTop)
        //   window.scrollTo(0, c - c / 6)
        // } else {
        //   window.removeEventListener('wheel', preventScroll, {
        //     passive: false,
        //   })
        // }
      }
      scrollToTop()
    },
    setConsentStatus(status) {
      document.body.classList.remove('cookie-consent')
      this.setConsentCookieStatus(status)
    },
    viewPrivacyPolicy() {
      let url =
        this.$i18n.locale === 'th'
          ? 'https://www.ofm.co.th/activity/cookiepolicy'
          : 'https://www.ofm.co.th/en/activity/cookiepolicy'
      window.open(url, '_blank')
    },

    async livechat() {
      let profile = this.profileType
      console.log('livechat', profile)
      //#region UAT
      // let initESW = function (gslbBaseURL) {
      //   embedded_svc.settings.displayHelpButton = true //Or false
      //   embedded_svc.settings.language = 'th' //For example, enter 'en' or 'en-US'
      //   embedded_svc.settings.enabledFeatures = ['LiveAgent']
      //   embedded_svc.settings.entryFeature = 'LiveAgent'
      //   embedded_svc.settings.chatbotAvatarImgURL =
      //     'https://officemate--sandboxpro--c.cs114.visual.force.com/resource/1578381769000/LogoEPro'
      //   embedded_svc.settings.defaultMinimizedText = '8:00 - 18:00'
      //   embedded_svc.init(
      //     'https://service.force.com',
      //     'https://sandboxpro-officemate.cs114.force.com/LiveAgent',
      //     gslbBaseURL,
      //     '00D1y0000008aRq',
      //     'E_Procurement',
      //     {
      //       baseLiveAgentContentURL:
      //         'https://c.la2-c1cs-hnd.salesforceliveagent.com/content',
      //       deploymentId: '5721y0000008OIj',
      //       buttonId: '57328000000L609',
      //       baseLiveAgentURL:
      //         'https://d.la2-c1cs-hnd.salesforceliveagent.com/chat',
      //       eswLiveAgentDevName:
      //         'EmbeddedServiceLiveAgent_Parent04I1y0000004C9cEAE_16e8746de83',
      //       isOfflineSupportEnabled: false,
      //     }
      //   )
      // }
      // if (!window.embedded_svc) {
      //   let s = document.createElement('script')
      //   s.setAttribute(
      //     'src',
      // 'https://officemate--prepro.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js'

      //   )
      //   s.onload = function () {
      //     initESW(null)
      //   }
      //   document.body.appendChild(s)
      // } else {
      //   initESW(null)
      // }
      //#endregion
      //#region  production
      let initESW = function (gslbBaseURL) {
        embedded_svc.settings.displayHelpButton = true //Or false
        embedded_svc.settings.language = 'th' //For example, enter 'en' or 'en-US'
        embedded_svc.settings.enabledFeatures = ['LiveAgent']
        embedded_svc.settings.entryFeature = 'LiveAgent'
        embedded_svc.settings.chatbotAvatarImgURL =
          'https://officemate--c.ap6.visual.force.com/resource/1565168592000/LogoOFM'
        embedded_svc.settings.defaultMinimizedText =
          '<div class="fs-11">ต้องการความช่วยเหลือ ?</div><div class="fs-9">ทักมาได้เลยค่ะ 8:00 - 22:00</div>'
        if (profile === 'eprocurement') {
          embedded_svc.init(
            'https://officemate.my.salesforce.com',
            'https://officemate.secure.force.com/LiveAgent',
            gslbBaseURL,
            '00D28000001uuUn',
            'EPro_Live_Chat',
            {
              baseLiveAgentContentURL:
                'https://c.la1-c1-ukb.salesforceliveagent.com/content',
              deploymentId: '5720K000000GwUS',
              buttonId: '57328000000L609',
              baseLiveAgentURL:
                'https://d.la1-c1-ukb.salesforceliveagent.com/chat',
              eswLiveAgentDevName: 'EPro_Live_Chat',
              isOfflineSupportEnabled: false,
            }
          )
        } else {
          embedded_svc.init(
            'https://officemate.my.salesforce.com',
            'https://officemate.secure.force.com/LiveAgent',
            gslbBaseURL,
            '00D28000001uuUn',
            'OFM_Live_Chat',
            {
              baseLiveAgentContentURL:
                'https://c.la1-c1-ukb.salesforceliveagent.com/content',
              deploymentId: '5720K000000GwRJ',
              buttonId: '57328000000L5zn',
              baseLiveAgentURL:
                'https://d.la1-c1-ukb.salesforceliveagent.com/chat',
              eswLiveAgentDevName: 'OFM_Live_Chat',
              isOfflineSupportEnabled: false,
            }
          )
        }
      }
      //#endregion

      //#region Uat
      // let initESW = function (gslbBaseURL) {
      //   embedded_svc.settings.displayHelpButton = true //Or false
      //   embedded_svc.settings.language = 'th' //For example, enter 'en' or 'en-US'
      //   embedded_svc.settings.enabledFeatures = ['LiveAgent']
      //   embedded_svc.settings.entryFeature = 'LiveAgent'
      //   embedded_svc.settings.chatbotAvatarImgURL =
      //     'https://officemate--c.ap6.visual.force.com/resource/1578889289000/LogoEPro'
      //   embedded_svc.settings.defaultMinimizedText =
      //     '<div class="fs-11">ต้องการความช่วยเหลือ ?</div><div class="fs-9">ทักมาได้เลยค่ะ 8:00 - 22:00</div>'
      //   if (profile === 'standard') {
      //     embedded_svc.init(
      //       'https://officemate--prepro.sandbox.my.salesforce.com',
      //       'https://officemate--prepro.sandbox.my.salesforce-sites.com/LiveAgent',
      //       gslbBaseURL,
      //       '00D0k0000009CpS',
      //       'OFM_Live_Chat',
      //       {
      //         baseLiveAgentContentURL:
      //           'https://c.la1-c1cs-hnd.salesforceliveagent.com/content',
      //         deploymentId: '5720k00000000JH',
      //         buttonId: '5730k00000000NO',
      //         baseLiveAgentURL:
      //           'https://d.la1-c1cs-hnd.salesforceliveagent.com/chat',
      //         eswLiveAgentDevName: 'OFM_Live_Chat',
      //         isOfflineSupportEnabled: false,
      //       }
      //     )
      //   } else if (profile === 'eprocurement') {
      //     embedded_svc.init(
      //       'https://officemate--prepro.sandbox.my.salesforce.com',
      //       'https://officemate--prepro.sandbox.my.salesforce-sites.com/LiveAgent',
      //       gslbBaseURL,
      //       '00D0k0000009CpS',
      //       'EPro_Live_Chat',
      //       {
      //         baseLiveAgentContentURL:
      //           'https://c.la1-c1cs-hnd.salesforceliveagent.com/content',
      //         deploymentId: '5720k00000000JH',
      //         buttonId: '57328000000L609',
      //         baseLiveAgentURL:
      //           'https://d.la1-c1cs-hnd.salesforceliveagent.com/chat',
      //         eswLiveAgentDevName: 'EPro_Live_Chat',
      //         isOfflineSupportEnabled: false,
      //       }
      //     )
      //   }
      // }
      //#endregion
      if (!window.embedded_svc) {
        let s = document.createElement('script')
        s.setAttribute(
          'src',
          'https://service.force.com/embeddedservice/5.0/esw.min.js'
        )
        s.onload = function () {
          initESW(null)
        }
        if (!document.getElementById('salesforce').childNodes.length) {
          document.getElementById('salesforce').appendChild(s)
        }

        // document.body.appendChild(s)
        this.isLiveChat = true
      } else {
        initESW('https://service.force.com')
        this.isLiveChat = false
      }
      //#endregion
    },
    async onBoundingLoad() {
      if (
        document.body.scrollTop > 500 ||
        document.documentElement.scrollTop > 500
      ) {
        this.isScrollToTop = true
        // console.log('add', this.isScrollToTop)
        document.getElementById('btn-scrollToTop').classList.add('show')
      } else {
        this.isScrollToTop = false
        // console.log('remove', this.isScrollToTop)
        document.getElementById('btn-scrollToTop').classList.remove('show')
      }
    },
  },
}
